import styled, { css } from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, radius, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { FileCopy } from '@styled-icons/material-outlined/FileCopy';
import { Check } from '@styled-icons/material-outlined/Check';

export const CopyButton = styled.button<{ $hasCopied?: boolean; $variant?: string }>`
  display: flex;
  cursor: ${({ $hasCopied }) => ($hasCopied ? 'default' : 'pointer')};
  width: auto;
  max-width: fit-content;
  border: 1px solid ${nafColor.primary.spruce};
  border-radius: ${radius.s};
  background-color: ${({ $hasCopied }) => ($hasCopied ? nafColor.primary.spruce : 'transparent')};
  color: ${({ $hasCopied }) => ($hasCopied ? nafColor.signature.white : nafColor.primary.spruce)};
  font: inherit;
  transition: background-color 0.1s ease, color 0.1s ease;

  ${({ $variant }) =>
    $variant === 'large' &&
    css`
      padding: ${spacing.space32} ${spacing.space48};

      @media (max-width: ${breakpoints.m}) {
        padding: ${spacing.space24} ${spacing.space32};
      }
    `}

  ${({ $variant }) =>
    $variant === 'small' &&
    css`
      padding: ${spacing.space16} ${spacing.space32};

      @media (max-width: ${breakpoints.m}) {
        padding: ${spacing.space8} ${spacing.space32};
      }
    `}
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(Text)`
  margin: 0;
  margin-right: ${spacing.space8};
`;

export const DiscountCode = styled(Text)`
  margin: 0;
`;

export const StyledFileCopy = styled(FileCopy)`
  margin-left: ${spacing.space16};
`;

export const StyledCheck = styled(Check)`
  margin-left: ${spacing.space16};
`;

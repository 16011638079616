import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { Link } from 'react-router-dom';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useCloudinary } from '../../hooks/useCloudinary';
import { actions as guides } from '../../redux/modules/consumerGuides';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import useSelector from '../../redux/typedHooks';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import TestList from '../../components/ConsumerTest/TestList/TestList';
import TestSearchSort from '../../components/ConsumerTest/TestSearchSort/TestSearchSort';
import { NotFound } from '../errors/NotFound';
import ConsumerTestPlaceholder from './Components/Placeholder/ConsumerTestPlaceholder';
import { GuideFilterGroup, ConsumerTestedProduct } from '../../../../types/consumerTestType';
import { ConsumerTestPaywall } from './Components/ConsumerTestPaywall/ConsumerTestPaywall';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import * as S from './Styles';

const TestFilter = loadable(() => import('./Components/TestFilter/TestFilter'));

const ConsumerTestGuide = () => {
  const { simpleToken } = useAuth0Token();
  const { isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();
  const cld = useCloudinary();
  const appInsights = useAppInsightsContext();
  const windowPath = useWindowLocation();
  const { slug } = useParams<{ slug: string }>();
  const { selectedGuide, isUpdatingGuide, isUpdatingProducts, testedProducts, errorState } = useSelector(
    (state) => state.consumerGuides,
  );
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({});
  const [filteredProducts, setFilteredProducts] = useState(testedProducts?.items ?? []);
  useTrackMetric(appInsights, 'Forbrukertest');

  // Request guide when slug changes
  useEffect(() => {
    if (slug && !isLoading) {
      dispatch(guides.getOneGuide.request(slug, { token: isAuthenticated ? simpleToken : undefined }));
    }
  }, [slug, isAuthenticated, isLoading, simpleToken, dispatch]);

  // Request tested products when selectedGuide changes
  useEffect(() => {
    if (selectedGuide?.id) {
      dispatch(guides.getTestedProducts.request(selectedGuide.id, { token: simpleToken }));
    }
  }, [selectedGuide, simpleToken, dispatch]);

  const { title, ingress, image, relatedArticle, userAccessibility, seoConfig } = selectedGuide;
  const seoDescription = seoConfig?.introduction ? seoConfig.introduction : ingress;
  const seoTitle = seoConfig?.title ? seoConfig.title : title;
  const relatedArticleLink = useDocumentUrlWithNode(relatedArticle);
  const guideImage =
    image &&
    image.publicId &&
    cld.image(image.publicId).resize(fill().width(324).height(282)).quality('auto:best').format('auto');

  useEffect(() => {
    if (testedProducts?.items?.length) {
      setFilteredProducts(testedProducts.items);
    }
  }, [testedProducts]);

  const calculateDynamicFilterCounts = (
    filters: GuideFilterGroup[],
    items: ConsumerTestedProduct[],
  ): GuideFilterGroup[] =>
    filters.map((filterGroup) => ({
      ...filterGroup,
      filterValues: filterGroup.filterValues.map((filterValue) => {
        const matchingIds = items.filter((item) => {
          const groupFilter = filters.find((f) => f.title === filterGroup.title);
          if (!groupFilter) return false;
          return groupFilter.filterValues.find((f) => f.label === filterValue.label)?.matchingIds.includes(item.id);
        });
        return {
          ...filterValue,
          numberOfMatchingIds: matchingIds.length,
        };
      }),
    }));

  const applyFilters = (filters: Record<string, string[]>, items: any[]) => {
    if (!Object.values(filters).some((selectedValues) => selectedValues.length > 0)) {
      setFilteredProducts(items);
      return;
    }

    const filteredItems = items.filter((item) =>
      Object.entries(filters).every(([filterGroup, selectedValues]) => {
        if (!selectedValues.length) return true;
        const groupFilter = testedProducts.filters.find((f) => f.title === filterGroup);
        if (!groupFilter) return true;
        const matchingIds = groupFilter.filterValues
          .filter((f) => selectedValues.includes(f.label))
          .flatMap((f) => f.matchingIds);
        return matchingIds.includes(item.id);
      }),
    );
    setFilteredProducts(filteredItems);
  };

  const handleFilterChange = (filterGroup: string, filterValue: string) => {
    setSelectedFilters((prevFilters) => {
      const currentGroupFilters = prevFilters[filterGroup] || [];
      const updatedGroupFilters = currentGroupFilters.includes(filterValue)
        ? currentGroupFilters.filter((value) => value !== filterValue)
        : [...currentGroupFilters, filterValue];
      const newFilters = { ...prevFilters, [filterGroup]: updatedGroupFilters };
      applyFilters(newFilters, testedProducts.items);
      return newFilters;
    });
  };

  const updatedFilters = calculateDynamicFilterCounts(testedProducts?.filters || [], filteredProducts);

  if (!selectedGuide) {
    return (
      <h1
        style={{
          minHeight: '600px',
          margin: 'auto',
          marginTop: '200px',
        }}
      >
        Fant ikke guiden!
      </h1>
    );
  }

  if (errorState) {
    return <NotFound />;
  }

  return (
    <LayoutWithMainContent
      title={title}
      description={ingress}
      url={windowPath}
      gtmArgs={{
        page_type: 'ConsumerTestPage',
        pageCategory: 'Forbrukertest',
        contentId: slug,
      }}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
    >
      <S.Wrapper>
        <Grid>
          <GridCol s="12" m="12" l="12" xl="12">
            <BreadCrumb />
            <S.TopWrapper>
              {isUpdatingGuide ? (
                <ConsumerTestPlaceholder />
              ) : (
                <>
                  <S.ImageWrapper>
                    {guideImage ? (
                      <AdvancedImage
                        alt={image?.altText || ''}
                        cldImg={guideImage}
                        quality="auto:good"
                        plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
                      />
                    ) : null}
                  </S.ImageWrapper>
                  <S.TopWrapperTextContent>
                    <Text tag="h1" variant={TextVariant.Header1}>
                      {title}
                    </Text>
                    <S.IngressWrapper>
                      <Text tag="p" variant={TextVariant.Ingress}>
                        {ingress}
                      </Text>
                    </S.IngressWrapper>
                    {relatedArticle && (
                      <S.ButtonWrapper>
                        <Link to={relatedArticleLink}>
                          <Button variant="secondary" type="button">
                            Les mer om testen her
                          </Button>
                        </Link>
                      </S.ButtonWrapper>
                    )}
                  </S.TopWrapperTextContent>
                </>
              )}
            </S.TopWrapper>
            {userAccessibility?.isAccessibleForUser === false ? (
              <S.PaywallWrapper>
                <ConsumerTestPaywall
                  paywallOptions={userAccessibility.paywallOptions}
                  paywallLink={userAccessibility.paywallLink}
                  paywallLinkSecondary={userAccessibility.paywallLinkSecondary}
                />
              </S.PaywallWrapper>
            ) : (
              <>
                <GridCol l="4" xl="4">
                  <S.MobileTestFilterWrapper>
                    <TestFilter
                      filters={updatedFilters}
                      selectedFilters={selectedFilters}
                      onFilterChange={handleFilterChange}
                    />
                  </S.MobileTestFilterWrapper>
                </GridCol>
                <TestSearchSort itemsCount={filteredProducts.length} />

                <S.LargeGrid>
                  <GridCol l="4" xl="4">
                    <S.DesktopTestFilterWrapper>
                      <TestFilter
                        filters={updatedFilters}
                        selectedFilters={selectedFilters}
                        onFilterChange={handleFilterChange}
                      />
                    </S.DesktopTestFilterWrapper>
                  </GridCol>
                  <GridCol l="8" xl="8">
                    <S.TestListWrapper>
                      <TestList
                        guideSlug={selectedGuide.slug}
                        testedProducts={filteredProducts}
                        isUpdatingProducts={isUpdatingProducts}
                      />
                    </S.TestListWrapper>
                  </GridCol>
                </S.LargeGrid>

                <S.SmallGrid>
                  <GridCol s="12" m="12">
                    <S.TestListWrapper>
                      <TestList
                        guideSlug={selectedGuide.slug}
                        testedProducts={filteredProducts}
                        isUpdatingProducts={isUpdatingProducts}
                      />
                    </S.TestListWrapper>
                  </GridCol>
                </S.SmallGrid>
              </>
            )}
          </GridCol>
        </Grid>
      </S.Wrapper>
    </LayoutWithMainContent>
  );
};

export default ConsumerTestGuide;

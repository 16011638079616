import React, { useEffect, useMemo, useState } from 'react';
import { TextVariant } from '@naf/text';
import FeedbackSurvey from '../../../../components/feedbacksurvey/FeedbackSurvey';
import { useWindowLocation } from '../../../../hooks/useWindowLocation';
import { SectionsNavigation } from '../../Components/SectionsNavigation/SectionsNavigation';
import { RelatedModels } from '../../Components/RelatedModels/RelatedModels';
import { LoaderContent } from '../../../LoaderPage';
import { Header } from '../../Components/Header';
import { ModalImage } from '../../Components/ModalImage';
import { ProductData } from '../../Components/ProductData/ProductData';
import { ProductTestInfoBox } from '../../Components/ProductTestInfoBox';
import { ConsumerTestedProduct } from '../../../../../../types/consumerTestType';
import BlockContent from '../../../../components/block-content/BlockContent';
import { GuideContentWrapper } from '../../Components/GuideContentWrapper/GuideContentWrapper';
import RelatedArticleList from '../../../../components/related-articles/RelatedArticleList';
import { CampaignBlock } from '../../../../components/block-content/components/types/components/CampaignBlock';
import * as S from './Styles';

interface TestedProductContentProps {
  product: ConsumerTestedProduct;
  guideSlug: string;
}

export type SectionKey = 'model' | 'aboutProduct' | 'aboutTest' | 'other';

export type ScrollSection = {
  key: SectionKey;
  label: string;
  isPresent: boolean;
  ref: React.RefObject<HTMLDivElement>;
  content: React.ReactNode;
};

const TestedProductContent: React.FC<TestedProductContentProps> = ({ product, guideSlug }) => {
  const path = useWindowLocation();
  const [currentComponentInView, setComponentInView] = useState<SectionKey>('aboutProduct');

  const scrollSections = useMemo(() => {
    if (!product) return [];

    return [
      {
        key: 'model',
        label: product?.title,
        isPresent: true,
        content: (
          <>
            <Header title={product?.title} ingress={product?.ingress} score={product?.score} />
            {product?.image && (
              <ModalImage sanityImages={Array.isArray(product?.image) ? product?.image : [product?.image]} />
            )}
          </>
        ),
      },
      {
        key: 'aboutTest',
        label: 'Om testen',
        isPresent: true,
        content: (
          <ProductTestInfoBox
            title={product?.consumerGuideInfoBox?.title}
            text={product?.consumerGuideInfoBox?.text}
            displayOption={product?.consumerGuideInfoBox?.displayOption}
          />
        ),
      },
      {
        key: 'aboutProduct',
        label: 'Generelt om modellen',
        isPresent: true,
        content: (
          <>
            <ProductData parameters={product?.parameters} />
            {product?.body && (
              <GuideContentWrapper>
                <BlockContent
                  value={product?.body}
                  isAccessibleForUser={product?.userAccessibility?.isAccessibleForUser}
                />
              </GuideContentWrapper>
            )}
            {product?.campaignBlock && product?.userAccessibility?.isAccessibleForUser && (
              <S.CampaignWrapper>
                <CampaignBlock value={product.campaignBlock} />
              </S.CampaignWrapper>
            )}
          </>
        ),
      },
      {
        key: 'other',
        label: 'Andre tester, tips og råd',
        isPresent:
          product?.relatedContent &&
          product?.relatedContent.length > 0 &&
          product?.userAccessibility?.isAccessibleForUser,
        content: (
          <S.RelatedContentWrapper>
            <S.SectionHeader variant={TextVariant.Header1} tag="h2">
              Andre tester, tips og råd
            </S.SectionHeader>
            <RelatedArticleList relatedContent={product?.relatedContent} />
          </S.RelatedContentWrapper>
        ),
      },
    ]
      .filter(({ isPresent }) => isPresent)
      .map((section) => ({
        ...section,
        ref: React.createRef<HTMLDivElement>(),
      })) as ScrollSection[];
  }, [product]);

  useEffect(() => {}, [currentComponentInView]);

  const scrollTo = (sectionKey: SectionKey) => {
    const { ref } = scrollSections.find(({ key }) => sectionKey === key);

    if (ref) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (!product) {
    return <LoaderContent />;
  }

  return (
    <S.Container>
      <S.Main>
        {scrollSections.map(({ key, content, ref }, i) => (
          <S.ScrollComponent index={i} key={key} fireOnInView={() => setComponentInView(key)} observerRef={ref}>
            {content}
          </S.ScrollComponent>
        ))}
        <S.Section $isBottomElement>
          <S.SectionContent $unset $relative>
            <RelatedModels guideSlug={guideSlug} productSlug={product?.slug} inSidebar={false} />
          </S.SectionContent>
        </S.Section>
        <S.Section>
          <S.SectionContent>
            <S.FeedbackMargin />
            <FeedbackSurvey contentUrl={path} />
          </S.SectionContent>
        </S.Section>
      </S.Main>
      <S.Aside>
        <SectionsNavigation
          guideSlug={guideSlug}
          slug={product?.slug}
          modelName={product?.title}
          sections={scrollSections}
          sectionInView={currentComponentInView}
          navigateToSection={scrollTo}
        />
      </S.Aside>
    </S.Container>
  );
};

export { TestedProductContent };

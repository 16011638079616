import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${spacing.space16};

  @media (max-width: ${breakpoints.s}) {
    flex-direction: column;
    gap: ${spacing.space24};
  }
`;

import React from 'react';
import { TextVariant } from '@naf/text';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { useCloudinary } from '../../../../hooks/useCloudinary';
import { ConsumerTestedProduct } from '../../../../../../types/consumerTestType';
import * as S from './Styles';
import { Variant } from './Styles';

type Props = {
  relatedProduct: ConsumerTestedProduct;
  guideSlug: string;
  isCurrent?: boolean;
  inSidebar?: boolean;
  variant: Variant;
};

export const RelatedModelItem: React.FC<Props> = ({ relatedProduct, guideSlug, isCurrent, inSidebar, variant }) => {
  const cld = useCloudinary();
  const cldImage = cld
    .image(relatedProduct?.image?.publicId)
    .resize(fill().width(50).height(38))
    .quality('auto:eco')
    .format('auto');

  return (
    <S.StyledLink
      to={`/forbrukertester/${guideSlug}/${relatedProduct.slug}`}
      $current={isCurrent}
      variant={variant}
      $inSidebar={inSidebar}
    >
      <S.Inner>
        <div>
          <S.Label tag="p">{relatedProduct?.parameterLabels[0]}</S.Label>
          <S.ProductName tag="p" variant={TextVariant.CaptionHeader}>
            {relatedProduct?.title}
          </S.ProductName>
        </div>
        <S.ImageContainer $variant={variant}>
          {relatedProduct?.image ? (
            <AdvancedImage
              cldImg={cldImage}
              alt={relatedProduct?.image?.altText || ''}
              plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
            />
          ) : null}
        </S.ImageContainer>
      </S.Inner>
    </S.StyledLink>
  );
};

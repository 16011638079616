import styled, { css } from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { breakpoints, spacing } from '@naf/theme';
import { GridCol, GridRow } from '@naf/grid';
import { Label } from '@naf/label';
import { Text } from '@naf/text';

const HorizontalPadding = css`
  padding-left: ${spacing.space48};
  padding-right: ${spacing.space48};

  @media (max-width: ${breakpoints.m}) {
    padding-left: ${spacing.space24};
    padding-right: ${spacing.space24};
  }
`;

const HeaderColorBox = styled.div`
  height: 275px;
  width: 100vw;
  position: absolute;
  left: 0;
  top: calc(-129px + 120px);
  z-index: -100;
  background: ${nafColor.neutral.neutral1};

  @media (max-width: ${breakpoints.m}) {
    top: calc(-129px + 80px);
    height: 259px;
  }

  @media (max-width: ${breakpoints.s}) {
    height: 232px;
  }
`;

const HeaderGridCol = styled(GridCol)`
  box-sizing: border-box;
  background: #fff;
  margin-top: ${spacing.space32};
  max-width: 736px;
  ${HorizontalPadding};

  @media (max-width: ${breakpoints.l}) {
    margin-top: ${spacing.space40};
  }

  @media (max-width: ${breakpoints.m}) {
    max-width: 579px;
    margin-top: ${spacing.space32};
  }

  @media (max-width: ${breakpoints.s}) {
    padding-left: ${spacing.space12};
    padding-right: ${spacing.space12};
    margin-left: -${spacing.space12};
    margin-right: -${spacing.space12};
  }

  p {
    margin: 0;
    margin-bottom: ${spacing.space8};
  }

  h1 {
    margin: ${spacing.space48} 0 0;

    @media (max-width: ${breakpoints.m}) {
      margin-top: ${spacing.space32};
    }
    @media (max-width: ${breakpoints.s}) {
      margin-top: ${spacing.space20};
    }
  }

  h2,
  h3 {
    margin: 0;
  }
`;

const BodyCol = styled(GridCol)`
  ${HorizontalPadding};

  @media (max-width: ${breakpoints.s}) {
    padding: 0;
  }
`;

const CustomDisplayCol = styled(GridCol)`
  ${HorizontalPadding};
  padding-top: ${spacing.space16};

  @media (max-width: ${breakpoints.s}) {
    padding: 0;
  }
`;

const LogIn = styled.div`
  display: flex;
  flex-direction: column;
  ${HorizontalPadding};

  @media (max-width: ${breakpoints.s}) {
    padding: ${spacing.space24} 0 0;
  }

  p {
    margin: 0 0 ${spacing.space4};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    margin: ${spacing.space16} 0 ${spacing.space32};
  }

  h3,
  p {
    margin: 0 0 ${spacing.space32};
  }

  .accordion > h3,
  .accordion p {
    margin: 0;
  }

  .accordion:last-of-type {
    margin-bottom: ${spacing.space32};
  }

  ul:not([id^='downshift']) {
    margin-bottom: ${spacing.space32};
  }

  form p {
    margin: 0;
  }

  form ~ button {
    margin-bottom: ${spacing.space32};
  }

  > div:first-child {
    display: flex;
    flex-direction: column;
  }

  .accordion + h3 {
    margin-top: ${spacing.space56};
    margin-bottom: ${spacing.space8};
  }

  h2 + h3 {
    margin-top: ${spacing.space24};
    margin-bottom: ${spacing.space8};
  }

  #videoWrapper {
    margin: 0;
    margin-bottom: ${spacing.space24};
  }
`;

const OtherTests = styled(GridRow)`
  margin-top: calc(${spacing.space64} - ${spacing.space32});
  ${HorizontalPadding};

  h2 {
    margin: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    margin-top: calc(${spacing.space56} - ${spacing.space24});
  }

  @media (max-width: ${breakpoints.s}) {
    margin-top: calc(${spacing.space40} - ${spacing.space24});
    padding: 0;
  }
`;

const ShortCutCol = styled(GridCol)`
  a {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const StyledLabel = styled(Label)`
  display: flex;
  width: fit-content;
  margin-bottom: ${spacing.space12};

  @media (max-width: ${breakpoints.s}) {
    margin-bottom: ${spacing.space8};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  white-space: pre;
  align-items: center;

  &:first-child {
    margin-bottom: ${spacing.space4};
  }

  h2 {
    margin: 0;
  }
  h3 {
    margin: 0;
  }
`;

const InlineText = styled(Text)`
  display: inline-flex;
  white-space: pre;
`;

const ProductPairCol = styled(GridCol)`
  display: flex;
  ${HorizontalPadding};
  margin-top: ${spacing.space16};
  margin-bottom: ${spacing.space32};

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space24};
  }

  @media (max-width: ${breakpoints.s}) {
    flex-direction: column;
    padding: 0;
  }
`;

const ProductPairBlock = styled.div<{ $index: number }>`
  display: inline-flex;
  flex: 1 0 50%;

  &:first-child > div {
    padding-right: ${spacing.space24};
  }
  &:last-child {
    padding-left: ${spacing.space24};
  }

  h2 {
    margin: 0;
    margin-bottom: ${spacing.space12};
  }

  h3,
  h5 {
    margin: 0;
  }

  a {
    margin: ${spacing.space32} 0 ${spacing.space24};
  }

  @media (max-width: ${breakpoints.s}) {
    width: 100%;
    flex: 0 1 auto;

    &:first-child > div {
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
      margin-top: ${spacing.space40};
    }

    &:after {
      display: none;
    }

    a {
      margin-bottom: ${spacing.space20};
    }

    h2 {
      margin-bottom: ${spacing.space8};
    }
  }

  ${({ $index }) =>
    $index === 0 &&
    css`
      &:after {
        content: '';
        height: 100%;
        width: 1px;
        background-color: rgba(224, 224, 224, 1);
        margin: auto;
      }
    `}
`;

const Content = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
`;

const Ingress = styled(Text)`
  margin: 0;
`;

const ProductCol = styled(GridCol)`
  ${HorizontalPadding};
  margin-top: -${spacing.space16};

  a {
    margin-top: ${spacing.space32};
  }

  @media (max-width: ${breakpoints.s}) {
    padding: 0;
  }
`;

const QuoteCol = styled(GridCol)`
  display: flex;
  justify-content: center;
  margin-bottom: ${spacing.space64};
  ${HorizontalPadding};

  blockquote {
    width: 100%;
  }

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space48};
  }

  @media (max-width: ${breakpoints.s}) {
    margin-bottom: ${spacing.space40};
    padding: 0;
  }
`;

export default {
  HeaderColorBox,
  HeaderGridCol,
  BodyCol,
  LogIn,
  Body,
  OtherTests,
  ShortCutCol,
  StyledLabel,
  TextWrapper,
  InlineText,
  ProductPairCol,
  ProductPairBlock,
  Content,
  Ingress,
  ProductCol,
  QuoteCol,
  CustomDisplayCol,
};

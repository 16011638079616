import React from 'react';
import { Button, ButtonVariant } from '@naf/button';
import { Link, useLocation } from 'react-router-dom';
import { InternalLinkType } from '../../../../types/internalLinkType';
import { ExternalLinkType } from '../../../../types/externalLinkType';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';
import * as S from './Styles';

interface ModularButtonProps {
  primaryButton: {
    buttonText: string;
    variant?: string;
    internalLink?: InternalLinkType;
    externalLink?: ExternalLinkType;
  };
  secondaryButton?: {
    buttonText?: string;
    internalLink?: InternalLinkType;
    externalLink?: ExternalLinkType;
  };
}

export const ModularButton = ({ primaryButton, secondaryButton }: ModularButtonProps) => {
  const primaryInternalLink = useDocumentUrlWithNode(primaryButton?.internalLink);
  const secondaryInternalLink = useDocumentUrlWithNode(secondaryButton?.internalLink);
  const datalayer = useGTMDataLayer();
  const location = useLocation();

  const handleTracking = (text: string, path: string) => {
    datalayer?.push({
      event: 'cta_click',
      cta_text: text,
      cta_location: location.pathname,
      cta_url: path,
      ctaPosition: 'content',
    });
  };

  const renderButton = (text: string, variant: string, internalLink?: string, externalLink?: ExternalLinkType) => {
    if (internalLink) {
      return (
        <Link to={internalLink}>
          <Button variant={variant as ButtonVariant} type="button" onClick={() => handleTracking(text, internalLink)}>
            {text}
          </Button>
        </Link>
      );
    }

    if (externalLink) {
      return (
        <Button
          variant={variant as ButtonVariant}
          type="button"
          href={externalLink.href}
          target={externalLink.blank ? '_blank' : '_self'}
          onClick={() => handleTracking(text, externalLink.href)}
        >
          {text}
        </Button>
      );
    }

    return null;
  };

  return (
    <S.ButtonContainer>
      {renderButton(
        primaryButton.buttonText,
        primaryButton.variant || 'signature',
        primaryInternalLink,
        primaryButton.externalLink,
      )}
      {secondaryButton &&
        secondaryButton.buttonText &&
        renderButton(secondaryButton.buttonText, 'outline', secondaryInternalLink, secondaryButton.externalLink)}
    </S.ButtonContainer>
  );
};

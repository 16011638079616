import React from 'react';
import styled from 'styled-components';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { breakpoints, spacing } from '@naf/theme';
import { RadioButton } from '@naf/radio-button';
import { Text, TextVariant } from '@naf/text';
import { CarMeta, ChargeTest, RangeTest, TrunkTest } from '../../../../types/carModelType';
import { useCloudinary } from '../../hooks/useCloudinary';

type Props = {
  tests: (RangeTest | ChargeTest)[];
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  sanityImages?: CarMeta['cloudinaryImages'];
};

export const VehicleVariantRadio = ({ tests, selected, setSelected, name, sanityImages }: Props) => {
  const cld = useCloudinary();
  return (
    <Container>
      <HeaderText variant={TextVariant.Header3} tag="p">
        {numberToNorwegianWordBelowThirteen(tests.length)} {tests.length === 1 ? 'variant' : 'varianter'} ble testet
        denne sesongen
      </HeaderText>
      <VariantRadioButtons>
        {tests.map((test: RangeTest | ChargeTest) => {
          const variantImage = sanityImages?.find(
            (image) => image.variant?.id === test.vehicleModelReference?.vehicleId,
          );
          const cloudinarySrc =
            variantImage?.publicId &&
            cld
              .image(variantImage.publicId)
              .resize(fill().width(64).height(48))
              .quality('auto:eco')
              .format('auto')
              .toURL();
          return (
            <VariantRadioButton
              selected={test.vehicleModelReference?.vehicleId === selected}
              onChange={() => setSelected(test.vehicleModelReference?.vehicleId)}
              key={test.vehicleModelReference?.vehicleId}
            >
              <RadioButton
                name={name}
                label={`${test.vehicleModelReference?.vehicleName} ${test.vehicleModelReference?.year}`}
                checked={test.vehicleModelReference?.vehicleId === selected}
                onChange={() => setSelected(test.vehicleModelReference?.vehicleId)}
                className="variantRadio"
              />
              {cloudinarySrc ? (
                <ImageContainer>
                  <img src={cloudinarySrc} alt={variantImage.alt} />
                </ImageContainer>
              ) : null}
            </VariantRadioButton>
          );
        })}
      </VariantRadioButtons>
    </Container>
  );
};

type TrunkProps = {
  tests: TrunkTest[];
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  name: string;
};

export const TrunkVehicleVariantRadio = ({ tests, selected, setSelected, name }: TrunkProps) => {
  const cld = useCloudinary();
  return (
    <VariantRadioButtons>
      {tests.map((test, i) => {
        const cloudinarySrc =
          test.cloudinaryImages?.length &&
          cld
            .image(test.cloudinaryImages[0].publicId)
            .resize(fill().width(64).height(48))
            .quality('auto:eco')
            .format('auto')
            .toURL();
        return (
          <VariantRadioButton selected={i === selected} key={test.testedVariant.id} onClick={() => setSelected(i)}>
            <RadioButton
              name={name}
              label={`${test.testedVariant.vehicleName} ${test.testedVariant.year}`}
              checked={i === selected}
              onChange={() => {}}
              className="variantRadio"
            />
            {cloudinarySrc ? (
              <ImageContainer>
                <img src={cloudinarySrc} alt={test.cloudinaryImages[0].alt} />
              </ImageContainer>
            ) : null}
          </VariantRadioButton>
        );
      })}
    </VariantRadioButtons>
  );
};

const Container = styled.div`
  margin-bottom: ${spacing.space48};
  @media (max-width: ${breakpoints.l}) {
    margin-bottom: ${spacing.space40};
  }
`;

const HeaderText = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space16};
`;

const VariantRadioButtons = styled.div`
  display: flex;
  gap: ${spacing.space32};
  cursor: pointer;

  @media (max-width: ${breakpoints.l}) {
    flex-wrap: wrap;
    gap: ${spacing.space16};
  }
`;

const VariantRadioButton = styled.div<{ selected: boolean }>`
  flex-grow: 1;
  flex-basis: 100%;
  padding: ${spacing.space16};
  background: ${({ theme, selected }) => (selected ? theme.background.important : 'transparent')};
  border: 1px solid ${({ theme, selected }) => (selected ? theme.background.important : theme.border.subtle)};
  border-radius: 2px;
  display: flex;
  gap: ${spacing.space16};
  align-items: center;
  justify-content: space-between;

  & .variantRadio {
    label {
      align-items: center;
    }

    span {
      font-weight: 600;
    }
  }
`;

const ImageContainer = styled.div`
  height: ${spacing.space48};
  width: ${spacing.space64};
  border-radius: 2px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    height: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
  }
`;

const numberToNorwegianWordBelowThirteen = (num: number) => {
  // Why 13? See Språkrådet:
  // > Små tall skriver vi som regel med bokstaver. Vi regner tall til og med tolv som små.
  // https://www.sprakradet.no/sprakhjelp/Skriveregler/Dato/#sma
  switch (num) {
    case 1:
      return 'En';
    case 2:
      return 'To';
    case 3:
      return 'Tre';
    case 4:
      return 'Fire';
    case 5:
      return 'Fem';
    case 6:
      return 'Seks';
    case 7:
      return 'Syv';
    case 8:
      return 'Åtte';
    case 9:
      return 'Ni';
    case 10:
      return 'Ti';
    case 11:
      return 'Elleve';
    case 12:
      return 'Tolv';
    default:
      return `${num}`;
  }
};

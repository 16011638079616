import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import useSelector from '../../../redux/typedHooks';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../../../lib/constants';
import { actions as guides } from '../../../redux/modules/consumerGuides';
// import { useSendGTMEventOnce } from '../../../hooks/useSendGTMEventOnce';
// import { NAFGTMEvent } from '../../../../../types/GTM/NAFGTMEvent';
// import createAPIQuery from '../../../lib/createURLQueries';
// import { UrlParamsType } from '../../../../../types/urlParamsType';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { useWindowLocation } from '../../../hooks/useWindowLocation';
import { NotFound } from '../../../loadable-elements/NotFound';
import { TestedProductContent } from './TestedProductContent/TestedProductContent';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import { LoaderContent } from '../../LoaderPage';

interface Props {
  guideSlug: string;
  testSlug: string;
}

export const path = 'forbrukertest/:guideSlug/:testSlug';

const TestedProductPage = ({ testSlug, guideSlug }: Props) => {
  const windowPath = useWindowLocation();
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, 'Testprodukt');
  const dispatch = useDispatch();
  const { simpleToken } = useAuth0Token();
  const { oneProduct, isUpdatingGuide, isUpdatingProducts, errorState } = useSelector((state) => state.consumerGuides);

  const { id, title, ingress, image, seoConfig } = oneProduct;
  const seoDescription = seoConfig?.introduction ? seoConfig.introduction : ingress;
  const seoTitle = seoConfig?.title ? seoConfig.title : title;

  // Fetch product and guide whenever testSlug or guideSlug changes
  useEffect(() => {
    if (testSlug) {
      dispatch(guides.clearTestedProducts());
      dispatch(guides.getOneProduct.request(testSlug, { token: undefined }));
    }
  }, [dispatch, testSlug]);

  useEffect(() => {
    if (guideSlug) {
      dispatch(guides.clearRelatedProducts());
      dispatch(guides.getOneGuide.request(guideSlug, { token: simpleToken }));
    }
  }, [dispatch, guideSlug, simpleToken]);

  // Fetch product again when a valid token becomes available
  useEffect(() => {
    if (testSlug && simpleToken) {
      dispatch(guides.getOneProduct.request(testSlug, { token: simpleToken }));
    }
  }, [dispatch, testSlug, simpleToken]);

  if (!testSlug || !guideSlug || errorState) {
    return <NotFound />;
  }

  if (isUpdatingGuide || isUpdatingProducts || !oneProduct) {
    return <LoaderContent />;
  }

  return (
    <LayoutWithMainContent
      title={title}
      description={ingress}
      url={windowPath}
      imgUrl={image?.srcSet?.jpgLarge || DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'consumerTestProductPage',
        pageCategory: 'Forbrukertest',
        contentId: id || '',
      }}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      isGuidePage
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Article,
      }}
    >
      <TestedProductContent product={oneProduct} guideSlug={guideSlug} />
    </LayoutWithMainContent>
  );
};

export default TestedProductPage;
